import React, { useState } from "react";
import { Link } from "react-router-dom";

import Image from '../../images/slider/1.png';
import ShapeBg from '../../images/slider/bg-shape.png';
import Shape1 from '../../images/slider/shape-1.svg';
import Shape2 from '../../images/slider/shape-2.svg';
import Shape3 from '../../images/slider/love.png';


const Hero = () => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    const [phoneNumber, setPhoneNumber] = useState('+88659789874');
    const handlePhoneNumberChange = (newPhoneNumber) => {
        setPhoneNumber(newPhoneNumber);
    };
    return (
        <section className="static-hero">
            <div className="hero-container">
                <div className="hero-inner">
                    <div className="container">
                        <div className="hero-content">
                            <div className="slide-title">
                                <h2>Donate with love for save A children life.</h2>
                            </div>
                            <div className="slide-text">
                                <p>Sheets containing passages more recently software..</p>
                            </div>
                            <div className="clearfix"></div>
                            <div className="btns">
                                <Link onClick={ClickHandler} to="about" className="theme-btn">All Campaign</Link>
                                <a href={`tel:${phoneNumber}`} className="call-us">
                                    <span className="text">Help Line:</span>
                                    <span className="nub">09 697-836-937</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="lawyer-pic fadeInRightSlow">
                        <img src={Image} alt="img" />
                    </div>
                </div>
            </div>
            <div className="bg-shape">
                <img src={ShapeBg} alt="img" />
            </div>
            <div className="shape-1">
                <img src={Shape1} alt="img" />
            </div>
            <div className="shape-2">
                <img src={Shape2} alt="img" />
            </div>
            <div className="shape-3">
                <img src={Shape3} alt="img" />
            </div>
        </section>
    )
}

export default Hero;