import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar'
import Hero2 from '../../components/hero2/Hero2';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import About2 from '../../components/about2/about2';
import CausesSectionS2 from '../../components/CausesSecionS2/CausesSecionS2';
import TeamSectionS2 from '../../components/TeamSectionS2/TeamSectionS2';
import EventSectionS2 from '../../components/EventSectionS2/EventSectionS2';
import CtaSection from '../../components/CtaSection/CtaSection';
import TestimonialSection from '../../components/TestimonialSection/TestimonialSection';
import BlogSectionS2 from '../../components/BlogSectionS2/BlogSectionS2';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Logo from '../../images/logo-2.svg'
const HomePage2 = () => {
    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-4'} Logo={Logo} />
            <Hero2 />
            <ServiceSection />
            <About2 />
            <CausesSectionS2 />
            <TeamSectionS2 />
            <EventSectionS2 />
            <CtaSection />
            <TestimonialSection />
            <BlogSectionS2 />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default HomePage2;