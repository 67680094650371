
import image1 from '../images/testimonial/1.png';
import image2 from '../images/testimonial/2.png';
import image3 from '../images/testimonial/3.png';

const TestimonialData = [
    {
        id: "01",
        text: "It to make a type specimen book software survived leap into electronic type sheets remaining essentially popularised in the with the release include passages recently with desktop.",
        title: "Ramisna Asbare",
        subtitle: "Lead Volunteer",
        image: image1
    },
    {
        id: "02",
        text: "It to make a type specimen book software survived leap into electronic type sheets remaining essentially popularised in the with the release include passages recently with desktop.",
        title: "Fitzgerald",
        subtitle: "Lead Volunteer",
        image: image2
    },
    {
        id: "03",
        text: "It to make a type specimen book software survived leap into electronic type sheets remaining essentially popularised in the with the release include passages recently with desktop.",
        title: "Edwards",
        subtitle: "Lead Volunteer",
        image: image3
    },

]

export default TestimonialData;