import React from "react";
import { Link } from "react-router-dom";

const CtaSection = () => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    return(
        <section className="wpo-cta-section section-padding">
            <div className="container">
                <div className="cta-wrap">
                    <div className="shape-1"></div>
                    <div className="shape-2"></div>
                    <div className="cta-text">
                        <h3>Let is worke together to make a difference</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    </div>
                    <div className="cta-btn">
                        <Link onClick={ClickHandler} to="/" className="theme-btn">volunteer</Link>
                    </div>
                    <div className="shape-3"></div>
                    <div className="shape-4"></div>
                </div>
            </div>
        </section>
    )
}

export default CtaSection;