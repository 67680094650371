// images
import blogImg1 from "../images/blog/img-1.png";
import blogImg2 from "../images/blog/img-2.png";
import blogImg3 from "../images/blog/img-2.png";

import recent1 from "../images/recent-posts/img-1.jpg";
import recent2 from "../images/recent-posts/img-2.jpg";
import recent3 from "../images/recent-posts/img-3.jpg";


import blogSingleImg1 from "../images/blog/1.jpg";
import blogSingleImg2 from "../images/blog/2.jpg";
import blogSingleImg3 from "../images/blog/3.jpg";



const blogs = [
    {
        id: '1',
        title: 'Giving The Poor Is A Great Satisfaction Of heart.',
        slug: '1',
        subtitle: 'It has survived not only five but also the leap intona electronic typesetting remaining unchanged.',
        create_at: '06 - August - 2024',
        screens: blogImg1,
        recent: recent1,
        blogSingleImg: blogSingleImg1,
        comment: '35',
        day: '28',
        month: 'AUGUST',
        blClass: 'format-standard-image',
        animation: '1200',
    },
    {
        id: '2',
        title: 'Support Our Charity’s Mission for a Brighter Future',
        slug: '2',
        subtitle: 'It has survived not only five but also the leap intona electronic typesetting remaining unchanged.',
        create_at: '08 - August - 2024',
        screens: blogImg2,
        recent: recent2,
        blogSingleImg: blogSingleImg2,
        comment: '35',
        day: '28',
        month: 'AUGUST',
        blClass: 'format-standard-image',
        animation: '1200',
    },
    {
        id: '3',
        title: 'Your Help Can Make Someone’s life Easier.',
        slug: '3',
        subtitle: 'It has survived not only five but also the leap intona electronic typesetting remaining unchanged.',
        create_at: '12 - August - 2024',
        screens: blogImg3,
        recent: recent3,
        blogSingleImg: blogSingleImg3,
        comment: '35',
        day: '28',
        month: 'AUGUST',
        blClass: 'format-standard-image',
        animation: '1200',
    },

   

];
export default blogs;