import React from 'react'
import Services from '../../api/Services';
import { Link } from 'react-router-dom'
import ins1 from '../../images/instragram/1.jpg'
import ins2 from '../../images/instragram/2.jpg'
import ins3 from '../../images/instragram/3.jpg'
import ins4 from '../../images/instragram/4.jpg'
import ins5 from '../../images/instragram/5.jpg'
import ins6 from '../../images/instragram/6.jpg'

const ServiceSidebar = (props) => {

    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <div className="wpo-single-sidebar">

            <div className="widget wpo-service-widget">
                <h2>Services</h2>
                <ul>
                    {Services.slice(0, 6).map((service, Sitem) => (
                        <li key={Sitem}><Link onClick={ClickHandler} to={`/service-single/${service.slug}`}>{service.title}</Link></li>
                    ))}
                </ul>
            </div>
            <div className="wpo-newsletter-widget widget">
                <h2>Newsletter</h2>
                <p>Join 20,000 Sabscribers!</p>
                <form className="form" onSubmit={SubmitHandler}>
                    <input type="text" placeholder="Email Address" />
                    <button type="submit">Sign Up</button>
                </form>
                <span>By signing up you agree to our <Link to="/contact">Privecy Policy</Link></span>
            </div>
            <div className="widget wpo-instagram-widget">
                <div className="widget-title">
                    <h2>Instagram</h2>
                </div>
                <ul className="d-flex">
                    <li><img src={ins1} alt="instragram" /></li>
                    <li><img src={ins2} alt="instragram" /></li>
                    <li><img src={ins3} alt="instragram" /></li>
                    <li><img src={ins4} alt="instragram" /></li>
                    <li><img src={ins5} alt="instragram" /></li>
                    <li><img src={ins6} alt="instragram" /></li>
                </ul>
            </div>
            <div className="wpo-contact-widget widget">
                <h2>How We Can <br /> Help You!</h2>
                <p>labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo
                    viverra maecenas accumsan lacus vel facilisis. </p>
                <Link onClick={ClickHandler} to="/contact">Contact Us</Link>
            </div>
        </div>

    )
}

export default ServiceSidebar;

