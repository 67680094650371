import React from 'react'
import { Link } from 'react-router-dom'
import abImg1 from '../../images/about.png'
import Shape1 from '../../images/about-bg-shape.svg'



const About = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="helpass-about-section section-padding">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-12">
                        <div className="about-left-content">
                            <div className="about-bg-shape">
                                <img src={Shape1} alt="" />
                            </div>
                            <img src={abImg1} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="about-right-content">
                            <div className="wpo-section-title">
                                <span>- About Us -</span>
                                <h2>we can help to make <span>smile</span> of a family.</h2>
                                <p>Lorem Ipsum has been the industry standard dummy text ever since the 1500s,
                                    when an unknown printer took a galley scrambled make type book.</p>
                            </div>
                            <div className="about-item">
                                <ul>
                                    <li className="icon"><i className="ti-calendar"></i></li>
                                    <li className="text">
                                        <h3>Date:</h3>
                                        <span>10.09.2024</span>
                                    </li>
                                </ul>
                                <ul>
                                    <li className="icon"><i className="ti-location-pin"></i></li>
                                    <li className="text">
                                        <h3>location:</h3>
                                        <span>1489 Langley Ave Grand Forks Afb, North.</span>
                                    </li>
                                </ul>
                            </div>
                            <Link onClick={ClickHandler} to="/about" className="theme-btn">Donate Now</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;



