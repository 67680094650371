import pimg1 from '../images/event/1.jpg'
import pimg2 from '../images/event/2.jpg'
import pimg3 from '../images/event/3.jpg'
import pimg4 from '../images/event/2.jpg'

import psimg1 from '../images/event-single/1.jpg'
import psimg2 from '../images/event-single/2.jpg'
import psimg3 from '../images/event-single/3.jpg'
import psimg4 from '../images/event-single/4.jpg'



const events = [
    {
        id: '1',
        title: 'Make donation to education and healthy food.',
        slug: '1',
        pimg: pimg1,
        psimg: psimg1,
        date: '9:00 am',
        location: 'New York',
        service: 'Child Education',
        duration: '12 Years',
        add: 'Millington, Ave, TN 38053',
        architect: 'Don Johnson',
        share: 'Industrial, Business',
        fax: ' 568 746 987'
    },
    {
        id: '2',
        title: 'Make a donation for healthy food for poor children.',
        slug: '2',
        pimg: pimg2,
        psimg: psimg2,
        date: '9:00 am',
        location: 'United Arab',
        service: 'Clean Water',
        duration: '12 Years',
        add: 'Millington, Ave, TN 38053',
        architect: 'Don Johnson',
        share: 'Industrial, Business',
        fax: ' 568 746 987'
    },
    {
        id: '3',
        title: 'Make a donation for Pure Water for African People.',
        slug: '3',
        pimg: pimg3,
        psimg: psimg3,
        date: '9:00 am',
        location: 'Singapore',
        service: 'Healthy Food',
        duration: '12 Years',
        add: 'Millington, Ave, TN 38053',
        architect: 'Don Johnson',
        share: 'Industrial, Business',
        fax: ' 568 746 987'
    },
    {
        id: '4',
        title: 'Make a donation for healthy food for poor children.',
        slug: '4',
        pimg: pimg4,
        psimg: psimg4,
        date: '9:00 am',
        location: 'New York',
        service: 'Healthy Lifestyle',
        duration: '12 Years',
        add: 'Millington, Ave, TN 38053',
        architect: 'Don Johnson',
        share: 'Industrial, Business',
        fax: ' 568 746 987'
    },

]
export default events;