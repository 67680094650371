import React from 'react'
import CountUp from 'react-countup';

const FunFact = (props) => {
    return (
        <section className="wpo-fun-fact-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="wpo-fun-fact-grids clearfix">
                            <div className="grid">
                                <div className="info">
                                    <h3><span><CountUp end={68} enableScrollSpy /></span>M</h3>
                                    <p>- Total Raised -</p>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="info">
                                    <h3><span><CountUp end={286} enableScrollSpy /></span></h3>
                                    <p>- Total Donar -</p>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="info">
                                    <h3><span><CountUp end={321} enableScrollSpy /></span>+</h3>
                                    <p>- Total Causes -</p>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="info">
                                    <h3><span><CountUp end={990} enableScrollSpy /></span>+</h3>
                                    <p>- Total Volunter -</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FunFact;




