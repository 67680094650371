import React from "react";
import Services from "../../api/Services";
import ServiceCard from "./ServiceCard"


const ServiceSection = (props) => {


    return (
        <section className="helpas-service-section section-padding">
            <div className="container">
                <div className="row">
                    {Services.slice(0, 4).map((Service, sitem) => (
                        <div className="col-lg-3 col-md-6 col-12" key={sitem}>
                            <ServiceCard title={Service.title} img={Service.sImg} slug={Service.slug} sdescription={Service.description} />
                        </div>
                    ))}

                </div>
            </div>
        </section>
    );
}

export default ServiceSection;









