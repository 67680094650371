import { Link } from 'react-router-dom'
import CountUp from 'react-countup';
import Logo from "../../images/logo.svg"


import inimg1 from "../../images/instragram/1.jpg"
import inimg2 from "../../images/instragram/2.jpg"
import inimg3 from "../../images/instragram/3.jpg"
import inimg4 from "../../images/instragram/4.jpg"
import inimg5 from "../../images/instragram/5.jpg"
import inimg6 from "../../images/instragram/6.jpg"

const ClickHandler = () => {
    window.scrollTo(10, 0);
}


const Footer = (props) => {


    return (
        <footer className="wpo-site-footer">
            <div className="wpo-upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget about-widget">
                                <div className="logo widget-title">
                                    <img src={Logo} alt="blog" />
                                </div>
                                <p>Make a type specimen book has survived not only five centuries,
                                    but also the leap into essentially unchanged..</p>
                                <h4>- Total Raised:</h4>
                                <div className="info">
                                    <h3>$<span><CountUp CountUp end={805684} enableScrollSpy /></span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-2 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Quick Links:</h3>
                                </div>
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/events-single/1">About Us</Link></li>
                                    <li><Link onClick={ClickHandler} to="/events-single/1">Meet Volunteer</Link></li>
                                    <li><Link onClick={ClickHandler} to="/events-single/1">What We Do</Link></li>
                                    <li><Link onClick={ClickHandler} to="/events-single/1">Our Pricing</Link></li>
                                    <li><Link onClick={ClickHandler} to="/events-single/1">Contact</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget wpo-service-link-widget">
                                <div className="widget-title">
                                    <h3>Contact </h3>
                                </div>
                                <div className="contact-ft">
                                    <p>Would you have any enquiries.Please feel free to contuct us</p>
                                    <ul>
                                        <li><i className="fi flaticon-email"></i>helpass@gmail.com</li>
                                        <li><i className="fi flaticon-phone-call"></i>+888 (123) 869523</li>
                                        <li><i className="fi flaticon-placeholder"></i>New York – 1075 Firs Avenue</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget instagram">
                                <div className="widget-title">
                                    <h3>Projects</h3>
                                </div>
                                <ul className="d-flex">
                                    <li><Link onClick={ClickHandler} to="/events-single/1"><img src={inimg1}
                                        alt="image" /></Link></li>
                                    <li><Link onClick={ClickHandler} to="/events-single/1"><img src={inimg2}
                                        alt="image" /></Link></li>
                                    <li><Link onClick={ClickHandler} to="/events-single/1"><img src={inimg3}
                                        alt="image" /></Link></li>
                                    <li><Link onClick={ClickHandler} to="/events-single/1"><img src={inimg4}
                                        alt="image" /></Link></li>
                                    <li><Link onClick={ClickHandler} to="/events-single/1"><img src={inimg5}
                                        alt="image" /></Link></li>
                                    <li><Link onClick={ClickHandler} to="/events-single/1"><img src={inimg6}
                                        alt="image" /></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wpo-lower-footer">
                <div className="container">
                    <div className="lower-wrap">
                        <div className="row">
                            <div className="col col-lg-6 col-12">
                                <p className="copyright"> Copyright &copy; 2024 Canun by <Link onClick={ClickHandler} to="/">wpOceans</Link>.
                                    All
                                    Rights Reserved.</p>
                            </div>
                            <div className="col col-lg-6 col-12">
                                <div className="social-icon">
                                    <ul>
                                        <li>
                                            <Link onClick={ClickHandler} to="#">
                                                <i className="ti-facebook"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={ClickHandler} to="#">
                                                <i className="ti-twitter-alt"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={ClickHandler} to="#">
                                                <i className="ti-instagram"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={ClickHandler} to="#">
                                                <i className="ti-google"></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;

