import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar'
import Hero4 from '../../components/Hero4/Hero4';
import About from '../../components/about/about';
import CausesSecion from '../../components/CausesSecion/CausesSecion';
import FunFact from '../../components/FunFact/FunFact';
import TeamSection from '../../components/TeamSection/TeamSection';
import EventSection from '../../components/EventSection/EventSection';
import TestimonialSection from '../../components/TestimonialSection/TestimonialSection';
import BlogSection from '../../components/BlogSection/BlogSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Logo2 from '../../images/logo-3.svg'

const HomePage4 = () => {
    return (
        <Fragment>
            <Navbar hclass="wpo-site-header-s3" Logo={Logo2}  />
            <Hero4 />
            <About />
            <CausesSecion />
            <TeamSection />
            <EventSection />
            <FunFact />
            <TestimonialSection />
            <BlogSection />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default HomePage4;