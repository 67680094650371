import simg1 from '../images/service/gift-box.svg'
import simg2 from '../images/service/planting.svg'
import simg3 from '../images/service/healthcare.svg'
import simg4 from '../images/service/graduate-cap.svg'

import sSimg1 from '../images/service-single/1.jpg'
import sSimg2 from '../images/service-single/2.jpg'
import sSimg3 from '../images/service-single/3.jpg'
import sSimg4 from '../images/service-single/4.jpg'



const Services = [
   {
      Id: '01',
      sImg: simg1,
      sSImg: sSimg1,
      title: 'We Donate',
      slug: '1',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
   },
   {
      Id: '02',
      sImg: simg2,
      sSImg: sSimg2,
      title: 'Mothly Gift',
      slug: '2',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
   },
   {
      Id: '03',
      sImg: simg3,
      sSImg: sSimg3,
      title: 'Gift Catalog',
      slug: '3',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
   },
   {
      Id: '04',
      sImg: simg4,
      sSImg: sSimg4,
      title: 'We educate',
      slug: '4',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
   },

]

export default Services;