import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import Logo from '../../images/logo.svg'
import EventSection from '../../components/EventSection/EventSection';
import CtaSection from '../../components/CtaSection/CtaSection';



const EventPageTwo = () => {



    return (
        <Fragment>
            <Navbar Logo={Logo} />
            <PageTitle pageTitle={'Event'} pagesub={'Event'} />
            <EventSection />
            <CtaSection/>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
}

export default EventPageTwo;