import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar'
import Hero3 from '../../components/hero3/hero3';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import CausesSectionS2 from '../../components/CausesSecionS2/CausesSecionS2';
import TeamSectionS2 from '../../components/TeamSectionS2/TeamSectionS2';
import EventSectionS2 from '../../components/EventSectionS2/EventSectionS2';
import BlogSectionS2 from '../../components/BlogSectionS2/BlogSectionS2';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Logo from '../../images/logo-3.svg'
const HomePage3 = () => {
    return (
        <Fragment>
            <Navbar Logo={Logo} hclass="wpo-site-header-s3" />
            <Hero3 />
            <ServiceSection />
            <CausesSectionS2 />
            <TeamSectionS2 />
            <EventSectionS2 />
            <BlogSectionS2 />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default HomePage3;