

import Cimg1 from '../images/causes/1.jpg'
import Cimg2 from '../images/causes/2.jpg'
import Cimg3 from '../images/causes/3.jpg'
import Cimg4 from '../images/causes/4.jpg'



import Csimg1 from '../images/cause-single/1.jpg'
import Csimg2 from '../images/cause-single/2.jpg'
import Csimg3 from '../images/cause-single/3.jpg'
import Csimg4 from '../images/cause-single/4.jpg'

const causesData = [
    {
        id: 1,
        slug:"1",
        title: "Education for poor children",
        category: "Education",
        image: Cimg1,
        Simage: Csimg1,
        raisedAmount: 8000,
        goalAmount: 10000,
        description: "Been the industry standard’s dummy text ever since the took make."
    },
    {
        id: 2,
        slug:"2",
        title: "Donate for healthy food",
        category: "Food",
        image: Cimg2,
        Simage: Csimg2,
        raisedAmount: 4000,
        goalAmount: 10000,
        description: "Been the industry standard’s dummy text ever since the took make."
    },
    {
        id: 3,
        slug:"3",
        title: "Donate for child day care ",
        category: "Education",
        image: Cimg3,
        Simage: Csimg3,
        raisedAmount: 8692,
        goalAmount: 10000,
        description: "Been the industry standard’s dummy text ever since the took make."
    },
    {
        id: 4,
        slug:"4",
        title: "Donate for healthy food",
        category: "Education",
        image: Cimg4,
        Simage: Csimg4,
        raisedAmount: 5000,
        goalAmount: 10000,
        description: "Been the industry standard’s dummy text ever since the took make."
    },
   

];

export default causesData;