import React from "react";



const TeamCard = ({ Timg, Ttitle, Tsubtitle }) => {


    return (
        <div className="team-single">
            <div className="image">
                <img src={Timg} alt="" />
                <div className="text">
                    <h2>{Ttitle}</h2>
                    <span>{Tsubtitle}</span>
                </div>
            </div>
        </div>
    )
}



export default TeamCard