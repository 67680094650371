import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SectionTitle from "../SectionTitle/SectionTitle";
import { Link } from "react-router-dom";
import causesData from "../../api/causes"


const CausesSecion = () => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const settings = {
        dots: false,
        arrows:true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                },
            },

        ],
    };

    return (
        <section className="helpass-causes-section section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <SectionTitle subtitle='- MAKE A DONATION -' title='Our Recent Causes' />
                    </div>
                </div>
                <div className="causes-wrap">
                    <div className="causes-slider">
                        <Slider {...settings}>
                            {causesData.map((cause, cid) => (
                                <div className="causes-item" key={cid}>
                                    <div className="image">
                                        <img src={cause.image} alt="" />
                                    </div>
                                    <div className="causes-content">
                                        <div className="causes-title">
                                            <span>- {cause.category} -</span>
                                            <h3><Link onClick={ClickHandler} to={`/cause-single/${cause.slug}`}>{cause.title}</Link></h3>
                                        </div>
                                        <div className="progress-item">
                                            <div className="progress">
                                                <div className="bar" style={{ width: `${(cause.raisedAmount / cause.goalAmount) * 100}%` }}>
                                                    <span></span>
                                                </div>
                                            </div>
                                            <div className="progres-label">
                                                <div className="label-on">
                                                    <p>Raised:<span>${cause.raisedAmount}</span></p>
                                                </div>
                                                <div className="label-two">
                                                    <p>Goal:<span>${cause.goalAmount}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="causes-text">
                                            <p>{cause.description}</p>
                                        </div>
                                    <Link onClick={ClickHandler} className="theme-btn-s2" to={`/cause-single/${cause.slug}`}>Donate Now</Link>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CausesSecion;
