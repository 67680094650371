import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import CausesSecion from '../../components/CausesSecion/CausesSecion';
import TeamSectionS2 from '../../components/TeamSectionS2/TeamSectionS2';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import Logo from "../../images/logo.svg"

const CausePageOn = () => {
    return (
        <Fragment>
            <Navbar Logo={Logo} />
            <PageTitle pageTitle={'Causes'} pagesub={'Causes'} />
            <CausesSecion />
            <TeamSectionS2/>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default CausePageOn;

