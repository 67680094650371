import timg1 from '../images/team/1.png'
import timg2 from '../images/team/2.png'
import timg3 from '../images/team/3.png'
import timg4 from '../images/team/4.png'





const Teams = [
   {
      Id: '1',
      img: timg1,
      title: 'Norbert Farias',
      subtitle: 'Charity Volunteer',
   },
   {
      Id: '2',
      img: timg2,
      title: 'Michelle Woods',
      subtitle: 'Charity Volunteer',
   },
   {
      Id: '3',
      img: timg3,
      title: 'Diane Fritz',
      subtitle: 'Charity Volunteer',
   },
   {
      Id: '4',
      img: timg4,
      title: 'John Wing',
      subtitle: 'Charity Volunteer',
   },





]

export default Teams;