import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import Logo from '../../images/logo.svg'
import CtaSection from '../../components/CtaSection/CtaSection';
import EventSectionS2 from '../../components/EventSectionS2/EventSectionS2';



const EventPagetwo = () => {



    return (
        <Fragment>
            <Navbar Logo={Logo} />
            <PageTitle pageTitle={'Event'} pagesub={'Event'} />
            <EventSectionS2 />
            <CtaSection />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
}

export default EventPagetwo;