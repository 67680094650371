import Slider from 'react-slick';
import { Link } from "react-router-dom";
import SectionTitle from "../SectionTitle/SectionTitle";
import Events from "../../api/events";
import Shape from "../../images/event/event-shape.png"


const EventSection = (props) => {

    const ClickHandlar = () => {
        window.scrollTo(10, 0);
    }

    const settings = {
        vertical: true,
        dots: false,
        arrows: true,
        slidesToShow: 3,
        responsive: [

            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                    dots: true,
                    slidesToScroll: 1
                }
            }
        ]
    };



    return (
        <section className="helpass-event-section section-padding">
            <div className="event-shape">
                <img src={Shape} alt="img" />
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-7">
                        <SectionTitle title="Join upcoming events" subtitle="- MAKE A DONATION -" />
                    </div>
                </div>
                <div className="event-wrap">
                    <div className="row align-items-end event-slider">
                        <Slider {...settings}>
                            {Events.slice(0, 4).map((event, ei) => (
                                <div className="col-lg-10" key={ei}>
                                    <div className="events-single-item">
                                        <div className="event-image">
                                            <img src={event.pimg} alt="img" />
                                        </div>
                                        <div className="event-content">
                                            <p>{event.architect}</p>
                                            <h2>{event.title}</h2>
                                            <div className="time">
                                                <ul>
                                                    <li className="text">
                                                        <h3>Time:<span>{event.date}</span></h3>
                                                    </li>

                                                    <li className="text">
                                                        <h3>Location:
                                                            <span>{event.location}</span></h3>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="event-btn">
                                            <Link onClick={ClickHandlar} to={`events-single/${event.slug}`} className="theme-btn">Book Ticket</Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EventSection;








