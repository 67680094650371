import React from "react";
import { Link } from 'react-router-dom'


const Hero3 = () => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="static-hero-s3">
            <div className="hero-container">
                <div className="hero-inner">
                    <div className="container">
                        <div className="hero-content">
                            <div className="slide-title">
                                <h2>Find out more about our 30 year history and life changing work in south africa</h2>
                            </div>
                            <div className="clearfix"></div>
                            <div className="btns">
                                <Link onClick={ClickHandler} to="/about" className="theme-btn-s3">Read More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape-1">
                <svg viewBox="0 0 200 200">
                    <path
                        d="M30.5,-44.1C39,-35.7,45.3,-26.2,48.9,-15.7C52.5,-5.3,53.5,6.2,49.4,15.2C45.3,24.1,36.1,30.6,27,35.1C17.9,39.6,9,42.1,-4,47.6C-17,53.2,-34,61.6,-47.3,58.5C-60.6,55.4,-70.3,40.7,-68.4,26.9C-66.6,13,-53.2,0,-47.3,-14.6C-41.5,-29.3,-43.3,-45.6,-36.7,-54.7C-30,-63.7,-15,-65.3,-2,-62.5C10.9,-59.7,21.9,-52.4,30.5,-44.1Z"
                        transform="translate(100 100)" />
                </svg>
            </div>
            <div className="shape-2"></div>
        </section>

    )
}

export default Hero3;



