

import SectionTitle from "../SectionTitle/SectionTitle"
import causesData from "../../api/causes"
import { Link } from "react-router-dom"

const ClickHandler = () => {
    window.scrollTo(10,0)
}
const CausesSectionS2 = () => {
    return (
        <section className="helpass-causes-section-s2 section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <SectionTitle title="Our Recent Causes" subtitle="- MAKE A DONATION -" />
                    </div>
                </div>
                <div className="causes-wrap">
                    {causesData.slice(0,3).map((cause, citem) => (
                        <div className="causes-item" key={citem}>
                            <div className="image">
                                <img src={cause.image} alt="img" />
                            </div>
                            <div className="causes-content">
                                <div className="causes-title">
                                    <span>- {cause.category} -</span>
                                    <h3><Link onClick={ClickHandler} to={`/cause-single/${cause.slug}`}>{cause.title}</Link></h3>
                                </div>
                                <div className="progress-item">
                                    <div className="progress">
                                        <div className="bar" style={{ width: `${(cause.raisedAmount / cause.goalAmount) * 100}%` }}>
                                            <span></span>
                                        </div>
                                    </div>
                                    <div className="progres-label">
                                        <div className="label-on">
                                            <p>Raised:<span>${cause.raisedAmount}</span></p>
                                        </div>
                                        <div className="label-two">
                                            <p>Goal:<span>${cause.goalAmount}</span></p>
                                        </div>
                                    </div>

                                </div>
                                <div className="causes-text">
                                    <p>{cause.description}</p>
                                </div>
                            </div>
                            <div className="causes-btn">
                                <Link onClick={ClickHandler} className="theme-btn-s2" to={`/cause-single/${cause.slug}`}>Donate Now</Link>
                            </div>
                        </div>
                    ))}
                   
                </div>
            </div>
        </section>
    )
}
export default CausesSectionS2;