import React from "react";
import { Link } from 'react-router-dom'
import hero1 from '../../images/slider/slide-5.jpg'
import love from '../../images/slider/love.png'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}
const Hero = () => {
    
    return (
        <section className="static-hero-s2">
            <div className="hero-container">
                <div className="hero-inner">
                    <div className="container">
                        <div className="hero-content">
                            <div className="slide-title">
                                <h2>All Lives Have Equal Balue.</h2>
                            </div>
                            <div className="slide-text">
                                <p>Sheets containing passages more recently software..</p>
                            </div>
                            <div className="clearfix"></div>
                            <div className="btns">
                                <Link onClick={ClickHandler} to="/about" className="theme-btn-s3">Donate Now</Link>
                                <a href="tel:+8825456715" className="call-us">
                                    <span className="text">Help Line:</span>
                                    <span className="nub">09 697-836-937</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="left-content">
                        <img src={hero1} alt="img" />
                    </div>
                </div> 
            </div>
            <div className="shape-3">
                <img src={love} alt="img" />
            </div>
            <div className="shape-boder-1"></div>
            <div className="shape-boder-2">
                <svg viewBox="0 0 200 200">
                    <path fill="#8072DB"
                        d="M40.4,-19.7C55.2,-13.6,72,3.3,70.8,19.2C69.6,35,50.3,49.8,30,57.9C9.7,66,-11.8,67.4,-28.7,59.4C-45.6,51.3,-58,33.7,-64.4,12.7C-70.8,-8.4,-71.3,-32.8,-59.5,-38.1C-47.8,-43.5,-23.9,-29.9,-5.6,-25.4C12.8,-21,25.6,-25.8,40.4,-19.7Z"
                        transform="translate(100 100)" />
                </svg>
            </div>
            <div className="shape-boder-3"></div>
        </section>
    )
}

export default Hero;


