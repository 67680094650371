import React from "react";
import { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Link } from 'react-router-dom'
import hero3 from '../../images/slider/slide-4.jpg'
import hero4 from '../../images/slider/slide-3.jpg'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const Hero4 = () => {

    return (
        <section className="wpo-hero-slider">
            <Swiper

                modules={[Navigation, A11y]}
                spaceBetween={0}
                slidesPerView={1}
                loop={true}
                speed={1800}
                parallax={true}
                navigation
            >
                <SwiperSlide>
                    <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero3})` }}>
                        <div className="container">
                            <div className="slide-content">
                                <div className="slide-title">
                                    <h2>Care and Support through shelterless kids</h2>
                                </div>
                                <div className="slide-text">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                        tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                                <div className="clearfix"></div>
                                <div className="slide-btn">
                                    <Link onClick={ClickHandler} to="/about" className="theme-btn">Start Donating</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero4})` }}>
                        <div className="container">
                            <div className="slide-content">
                                <div className="slide-title">
                                    <h2>Giving Hope to the Homeless People</h2>
                                </div>
                                <div className="slide-text">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                        tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                                <div className="clearfix"></div>
                                <div className="slide-btn">
                                    <Link onClick={ClickHandler} to="/about" className="theme-btn">Start Donating</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                ...
            </Swiper>
        </section>
    )
}
export default Hero4;
