import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SectionTitle from "../SectionTitle/SectionTitle";

import Testimonial from "../../api/testimonial"



const TestimonialSection = () => {
    const settings = {
        vertical: true,
        dots: false,
        arrows: true,
        responsive: [

            {
                breakpoint: 991,
                settings: {
                    arrows: false,
                    dots: true,
                    vertical: false,
                }
            }
        ]
    };

    return (
        <section className="helpass-testimonial-section section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-7">
                        <SectionTitle subtitle="- HAPPY VOLUNTEER -" title="Our Happy Volunteer" />
                    </div>
                </div>
                <div className="testimonial-wrap">
                    <div className="testimonial-slider">
                        <Slider {...settings}>
                            {Testimonial.slice(0, 3).map((Testimonial, item) => (
                            <div className="item" key={item}>
                                <div className="testimonial-content" >
                                    <div className="image">
                                        <img src={Testimonial.image} alt="img" />
                                    </div>
                                    <div className="text">
                                        <p>{Testimonial.text}</p>
                                        <h2>{Testimonial.title}</h2>
                                        <span>{Testimonial.subtitle}</span>
                                    </div>
                                </div>
                            </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TestimonialSection







