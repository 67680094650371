import { Link } from "react-router-dom";
import SectionTitle from "../SectionTitle/SectionTitle";
import Events from "../../api/events";
import Shape from "../../images/event/event-shape.png"


const EventSectionS2 = (props) => {

    const ClickHandlar = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="helpass-event-section-s2  section-padding">
            <div className="event-shape">
                <img src={Shape} alt="img" />
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-7">
                        <SectionTitle title="Join upcoming events" subtitle="- MAKE A DONATION -" />
                    </div>
                </div>
                <div className="event-wrap">
                    <div className="row align-items-end event-slider">
                        {Events.slice(0, 3).map((event, ei) => (
                            <div className="col-lg-4 col-md-6 col-12" key={ei}>
                                <div className="events-single-item">
                                    <div className="event-image">
                                        <img src={event.pimg} alt="img" />
                                    </div>
                                    <div className="event-content">
                                        <p>{event.architect}</p>
                                        <h2>{event.title}</h2>
                                        <div className="time">
                                            <ul>
                                                <li className="text">
                                                    <h3>Time:<span>{event.date}</span></h3>
                                                </li>

                                                <li className="text">
                                                    <h3>Location:
                                                        <span>{event.location}</span></h3>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="event-btn">
                                        <Link onClick={ClickHandlar} to={`events-single/${event.slug}`} className="theme-btn">Book Ticket</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EventSectionS2;








